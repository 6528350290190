export default [
    {
        "tag":"Registered users",
        "value":7845
    },
    {
        "tag":"Counselling and conversations",
        "value":5623
    },
    {
        "tag":"Countries",
        "value":6
    },
    {
        "tag":"General users",
        "value":9546
    },
    {
        "tag":"MoM Growth",
        "value":37
    }
]